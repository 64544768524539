<template>
  <v-container fluid>
    <v-card v-if="tasksSettings.filter" class="mb-3">
      <v-card-actions class="py-0 px-5">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field v-model="tasksSettings.search" prepend-icon="fa-search" label="Meklēt" single-line hide-details clearable/>
          </v-col>
          <v-col cols="12" md="2">
            <v-select label="Status" prepend-icon="fa-toggle-on" v-model="status" :items="statuses" item-text="text" item-value="value" clearable/>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-tabs>
        <v-tab>Reklāma</v-tab>
        <v-tab @click="getUsers">Menedžeris</v-tab>
        <v-tab @click="getTasks">Uzdevumi</v-tab>
        <v-tab @click="getComments">Komentāriji</v-tab>
        <v-tab @click="getCompany">Uzņēmums</v-tab>
        <v-tab-item>
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field label="Nosaukums" v-model="advertisement.name" outlined autofocus disabled/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Budžets" v-model="advertisement.budget" outlined disabled/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Ad set sk." v-model="advertisement.ad_set" outlined/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Type" v-model="advertisement.type" outlined disabled/>
                </v-col>
                <v-col cols="12" class="d-flex justify-space-between">
                  <v-switch v-model="advertisement.target_type" value="L" label="Lead"></v-switch>
                  <v-switch v-model="advertisement.target_type" value="T" label="Target"></v-switch>
                  <v-switch v-model="advertisement.target_type" value="R" label="Reach"></v-switch>
                  <v-switch v-model="advertisement.target_type" value="LL" label="Lookalike"></v-switch>
                  <v-switch v-model="advertisement.target_type" value="PO" label="Post"></v-switch>
                  <v-switch v-model="advertisement.target_type" value="P" label="Product set"></v-switch>
                  <v-switch v-model="advertisement.target_type" value="RM" label="Remarketing"></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="advertisement.date_start"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="advertisement.date_start"
                        label="Sākuma datums"
                        prepend-icon="fa-calendar"
                        readonly
                        outlined
                        disabled
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="advertisement.date_start" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(advertisement.date_start)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6">
                  <v-dialog
                    ref="dialog2"
                    v-model="modal2"
                    :return-value.sync="advertisement.date_end"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="advertisement.date_end"
                        label="Datuma beigas"
                        prepend-icon="fa-calendar"
                        readonly
                        outlined
                        disabled
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="advertisement.date_end" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog2.save(advertisement.date_end)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Apraksts" v-model="advertisement.description" outlined disabled/>
                </v-col>
              </v-row>
            </v-card-text>
            <AlertResponse :response="alert"/>
            <v-card-actions>
              <v-btn color="primary" :to="{name: 'advertisementEdit', params: {id: this.$route.params.id}}">Update</v-btn>
              <v-spacer/>
              <v-btn color="error" @click="deleteAdvertisement">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-data-table
              :headers="headerUsers"
              :items="actions"
              item-key="item.id"
              :loading="usersSettings.loading"
              loading-text="Notiek ielāde... Lūdzu, uzgaidiet">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-dialog v-model="usersSettings.dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Pievienot</v-btn>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-autocomplete
                                label="User"
                                :items="users"
                                item-text="name"
                                item-value="id"
                                v-model="usersSettings.editedItem.user_id"
                                outlined
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field v-model="usersSettings.editedItem.amount" label="Amount"/>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="usersSettings.dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn @click="editItem(item)" icon mdall color="primary" class="mr-2">
                  <v-icon mdall>fa-pen</v-icon>
                </v-btn>
                <v-btn @click="deleteItem(item)" icon mdall color="error">
                  <v-icon mdall>fa-trash-alt</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <v-card-text>
              <v-btn @click="saveUsers" color="primary">Save</v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-toolbar dense flat>
              <v-spacer></v-spacer>
              <v-btn icon :to="{name: 'taskCreate'}" color="primary">
                <v-icon>fa-plus</v-icon>
              </v-btn>
              <v-btn icon color="primary" @click="tasksSettings.filter = !tasksSettings.filter">
                <v-icon>fa-sliders-h</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table
              :headers="headersTasks"
              :items="tasks"
              item-key="item.id"
              :loading="tasksSettings.loading"
              loading-text="Notiek ielāde... Lūdzu, uzgaidiet"
              :search="tasksSettings.search">
              <template v-slot:item.actions="{ item }">
                <v-btn icon color="primary" :to="{name: 'taskShow', params: {id: item.id}}">
                  <v-icon>fa-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-card-text></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field label="Nosaukums" v-model="company.name" outlined disabled/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Budžets" v-model="company.budget" outlined disabled/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field label="Budžets datums" v-model="company.budget_date" outlined disabled/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Tālrunis" v-model="company.phone" outlined disabled/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="E-pasts" v-model="company.email" outlined disabled/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Vaditajs" v-model="company.director" outlined disabled/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Juridiskā adrese" v-model="company.legal_address" outlined disabled/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Faktiskā adrese" v-model="company.actual_address" outlined disabled/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field label="Reģistrācijas numurs" v-model="company.reg_number" outlined disabled/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field label="PVN maksātāja numurs" v-model="company.vat_reg_number" outlined disabled/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field label="Kredītiestādes nosaukums" v-model="company.bank_name" outlined disabled/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field label="Kods SWIFT/BIC" v-model="company.bank_swift_bic" outlined disabled/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Konta numurs" v-model="company.bank_iban" outlined disabled/>
                </v-col>
                <v-col cols="12" md="9">
                  <v-textarea label="Apraksts" v-model="company.description" outlined disabled/>
                </v-col>
                <v-col cols="12" md="3">
                  <v-color-picker
                    v-model="company.color"
                    mode="hexa"
                    dot-size="25"
                    swatches-max-height="200"
                    disabled
                  ></v-color-picker>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'
import AlertResponse from '@/components/AlertResponse'

export default {
  name: 'AdvertisementShow',
  components: {
    AlertResponse
  },
  data: () => ({
    advertisementId: null,
    alert: null,
    modal: false,
    modal2: false,
    advertisement: {
      company_id: '',
      name: '',
      date_start: '',
      date_end: '',
      type: '',
      target_type: [],
      budget: '',
      ad_set: '',
      description: '',
      sort_order: 100,
      status: 1
    },
    users: [],
    actions: [],
    usersSettings: {
      loading: true,
      filter: false,
      modal: false,
      modal2: false,
      dialog: false,
      dialogDelete: false,
      search: '',
      status: '',
      editedIndex: -1,
      editedItem: {
        user_id: '',
        amount: 0
      },
      defaultItem: {
        user_id: '',
        amount: 0
      }
    },
    company: {
      id: '',
      name: '',
      description: '',
      phone: '',
      email: '',
      director: '',
      reg_number: '',
      vat_reg_number: '',
      legal_address: '',
      actual_address: '',
      bank_name: '',
      bank_swift_bic: '',
      bank_iban: '',
      budget: '',
      budget_date: '',
      color: '',
      sort_order: 100,
      status: 1
    },
    tasksSettings: {
      loading: true,
      filter: false,
      search: '',
      status: ''
    },
    tasks: [],
    commentsSettings: {
      loading: true,
      filter: false,
      search: '',
      status: ''
    },
    comments: []
  }),
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  computed: {
    statuses () {
      return this.$store.state.statuses
    },
    headerUsers () {
      return [
        {
          text: 'ID',
          value: 'user_id'
        },
        {
          text: 'Menedžeris',
          value: 'name'
        },
        {
          text: 'Budžets',
          value: 'amount'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    },
    headersTasks () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Date Start',
          value: 'date_start'
        },
        {
          text: 'Date End',
          value: 'date_end'
        },
        {
          text: 'Status',
          value: 'status',
          filter: value => {
            if (this.status === 0 || this.status === 1) {
              return value === this.status
            }
            if (!this.status) return true
          }
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    },
    headersComments () {
      return [
        {
          text: 'ID',
          value: 'id'
        },

        {
          text: 'User',
          value: 'user.name'
        },
        {
          text: 'Text',
          value: 'text'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    editItem (item) {
      this.usersSettings.editedIndex = this.actions.indexOf(item)
      this.usersSettings.editedItem = Object.assign({}, item)
      this.usersSettings.dialog = true
    },
    deleteItem (item) {
      this.usersSettings.editedIndex = this.actions.indexOf(item)
      this.usersSettings.editedItem = Object.assign({}, item)
      this.usersSettings.dialogDelete = true
    },
    deleteItemConfirm () {
      this.actions.splice(this.usersSettings.editedIndex, 1)
      this.closeDelete()
    },
    close () {
      this.usersSettings.dialog = false
      this.$nextTick(() => {
        this.usersSettings.editedItem = Object.assign({}, this.usersSettings.defaultItem)
        this.usersSettings.editedIndex = -1
      })
    },
    closeDelete () {
      this.usersSettings.dialogDelete = false
      this.$nextTick(() => {
        this.usersSettings.editedItem = Object.assign({}, this.usersSettings.defaultItem)
        this.usersSettings.editedIndex = -1
      })
    },
    save () {
      if (this.usersSettings.editedIndex > -1) {
        Object.assign(this.actions[this.usersSettings.editedIndex], this.usersSettings.editedItem)
      } else {
        this.actions.push(this.usersSettings.editedItem)
      }
      this.close()
    },
    getAdvertisement () {
      this.advertisementId = this.$route.params.id
      axios.get('advertisements/' + this.advertisementId).then(response => {
        this.advertisement = response.data
      }).catch(error => {
        alert(error)
      })
    },
    deleteAdvertisement () {
      const isDelete = confirm('Vai vēlaties dzēst ierakstu?')
      if (isDelete) {
        axios.delete('advertisements/' + this.advertisementId).then(response => {
          this.alert = response.data
          setTimeout(() => this.$router.push({ name: 'advertisementIndex' }), 2000)
        })
      }
    },
    getUsers () {
      axios.get('advertisements/' + this.advertisementId + '/users').then(response => {
        this.actions = response.data
        this.usersSettings.loading = false
      })
      axios.get('users').then(response => {
        this.users = response.data
      })
    },
    saveUsers () {
      axios.post('advertisements/' + this.advertisementId + '/users', { actions: this.actions }).then(() => {
        this.getUsers()
      }).catch(error => {
        this.alert = error.data
      })
    },
    getTasks () {
      this.tasksSettings.loading = true
      axios.get('advertisements/' + this.advertisementId + '/tasks').then(response => {
        this.tasks = response.data
        this.tasksSettings.loading = false
      })
    },
    getComments () {
      this.commentsSettings.loading = true
      axios.get('advertisements/' + this.advertisementId + '/comments').then(response => {
        this.comments = response.data
        this.commentsSettings.loading = false
      })
    },
    getCompany () {
      axios.get('advertisements/' + this.advertisementId + '/company').then(response => {
        this.company = response.data
      })
    }
  },
  created () {
    this.getAdvertisement()
  }
}
</script>

<style scoped>

</style>
